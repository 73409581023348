.catalogo {
    padding-bottom: 40px;

    &__hero {
        position: relative;
        height: 220px;
        overflow: hidden;

        &__img {
            position: absolute;
            width: auto;
            height: 100%;
            top: 50%;
            left:50%;
            transform: translate3d(-50%, -50%, 0);
            z-index: 0;

            @include mq(md){
                width: 100%;
                height: auto;
            }
        }

        &__title {
            position: absolute;
            top:40%;
            font-size: 36px;
            color: $white;
            text-align: center;
            width: 100%;
            z-index: 2;
            font-weight: 700;
            font-family: $altFont;

            @include mq(sm) {
                font-size: 42px;
            }
        }
    }

    &__book {
        .col-xs-24 {
            position: static;
        }

        &__container {
            position: relative;
        }

        &__products {
            &__heading {
                font-weight: 700;
                padding: 0 15px 20px;
                font-size: 14px;
                display: none;

                @include mq(md) {
                    display: block;
                }
            }
            &__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            &__item {
                flex-basis: 100%;
                width: 100%;
                max-width: 300px;
                margin-bottom: 40px;

                @include mq(md){
                    flex-basis: 25%;
                    width: 25%;
                }
            }
            &__footer {
                padding: 0 15px 20px;
            }
        }
    }
}
