.home {
    &__hero {
        &__slide {
            height: calc(100vh - 130px);
            background: url(/images/home/slider-back.jpg) no-repeat center center;
            background-size: cover;

            .container,
            .row {
                height: 100%;
            }

            &__imgcol {
                display: flex;
                justify-content: center;
                align-items: center;


                img {
                    width: 125%;
                    height: auto;
                    transform: translateX(-10%);
                }

                @include mq(sm){
                    float: right;
                    height: 100%;
                }
            }

            &__textcol {
                display: flex;
                justify-content: center;
                align-items: center;

                @include mq(sm){
                    height: 100%;
                }
            }

            &__text {
                text-align: center;

                @include mq(md) {
                    text-align: left;
                }

                h2 {
                    font-family: $altFont;
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 700;
                    color: $white;
                    margin: 0 0 20px;

                    @include mq(sm) {
                        font-size: 36px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    &__popular {
        padding-bottom: 40px;
    }

    &__highlights {
        text-align: center;

        &__item {
            padding-bottom: 40px;
        }

        &__img {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 0;
            padding-bottom: 68%;

            img {
                display: block;
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
            }
        }

        &__title {
            color: $mainColor;
            font-size: 24px;
            text-align: center;
            font-family: $altFont;
            font-weight: 700;
            margin: 20px 0 20px;
        }

        &__desc {
            font-size: 14px;
            line-height: 20px;
            padding: 0 40px;
            margin: 0;
        }
    }

    &__blurb{
        padding: 40px 0;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            height: 240px;
            justify-content: space-between;
            padding-bottom: 40px;
        }

        &__icon {
            display: block;
        }

        &__name {
            font-size: 24px;
            color: $mainColor;
            font-family: $altFont;
            margin: 0;
            font-weight: 700;
        }
    }
}
