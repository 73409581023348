.footer {
    box-shadow: 0 -1px 1px rgba(0,0,0,.15);
    padding-top: 40px;

    &__top {

        &__items {
            @include mq(sm) {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }
        }

        &__item {
            flex-basis:20%;
            min-width: 190px;
            color: $mainColor;
            padding-bottom: 20px;

            &__title {
                font-weight: 700;
                font-size: 18px;
            }

            ._logos {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                @include mq(sm) {
                    flex-direction: column;
                }

                img {
                    display: block;
                    margin-bottom: 15px;
                }
            }

            ._data {
                color: $textDark;
                display: block;
                font-size: 14px;
                strong{
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
    }

    &__social{
        background: $lightestGray;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        h4 {
            font-size: 24px;
            color: $mainColor;
            font-weight: 700;
        }

        i {
            font-size: 36px;
            display: block;
            margin-left: 15px;
        }
    }

    &__bottom {
        background: $mainColor;
        padding: 20px 0;
        color: $white;

        &__links {
            @include mq(sm){
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }

            a {
                display: block;
                padding: 2px 5px;
                margin: 10px 15px;
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

}
