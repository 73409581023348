.busqueda {
    padding-bottom: 40px;

    &__title {
        font-size: 36px;
        color: $mainColor;
        text-align: left;
        width: 100%;
        font-weight: 700;
        font-family: $altFont;
        margin: 0 0 20px;

        @include mq(sm) {
            font-size: 42px;
            text-align: center;
        }
    }
}
