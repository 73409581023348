.product {
    width: 100%;
    position: relative;
    padding: 0 15px;
    text-align: center;

    &__img {
        width: 100%;
        height: 0;
        padding-bottom: 110%;
        position: relative;

        img{
            position: absolute;
            top:0;
            width: 100%;
        }
    }

    &__data {

        &__name {
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            text-transform: uppercase;
            padding: 20px 0 10px;
        }

        &__stats {
            display: none;
        }

        &__desc {
            font-size: 14px;
            line-height: 22px;
            margin: 10px 0;
        }

        &__price {
            font-size: 18px;
            margin: 0 0 20px;
            font-weight: 700;
        }
    }

    &__star-rating {
        $s:&;

        width: 80px;
        height: 14px;
        margin: auto;
        position: relative;

        &__level {
            @include trans;

            width: 0;
            height: 14px;
            position: absolute;
            left: 0;
            top:0;
            z-index: 1;
            background: $mainColor;
        }

        &__mask {
            background: url(/images/star-rating-mask.png) no-repeat center center;
            background-size: cover;
            width: 80px;
            height: 14px;
            position: absolute;
            left: 0;
            top:0;
            z-index: 2;
            pointer-events: none;
        }

        &--input {
            #{$s}__level {
                display: flex;
                background: transparent;
                width: 100%;

                i{
                    @include trans;

                    background: transparent;
                    width: 20%;

                    &.-sel {
                        background: $mainColor;
                    }
                }
            }
        }
    }

}
