.breadcrumb {
    padding: 20px 0;
    background: none;
    &__list {
        display: flex;
        flex-wrap: wrap;

        li {
            font-size: 12px;
            white-space: nowrap;

            @include mq(sm) {
                font-size: 14px;
            }

            &:after {
                content: '/';
                padding: 0 10px;
            }

            a {
                text-decoration: underline;
            }

            &.active {
                opacity: .5;

                &:after {
                    display: none;
                }
            }
        }
    }
}
