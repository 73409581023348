.producto {
    padding-bottom: 40px;

    &__showcase {

        &__img {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            width: 100%;
            overflow: hidden;

            img {
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate3d(-50%,-50%,0);
            }
        }

        &__data {
            padding: 20px 0;
        }

        &__title {
            color: $mainColor;
            font-size: 24px;
            font-weight: 700;
            margin: 0;

            @include mq(sm) {
                font-size: 32px;
            }
        }

        &__rating {
            .product__star-rating {
                margin: 10px 0;

                @include mq(sm) {
                    margin: 10px 10px 10px 0;
                }
            }

            @include mq(sm) {
                display: flex;
            }
        }

        &__reviews {
            a {
                color: $mainColor;
                font-size: 12px;
            }

            @include mq(sm) {
                margin: 5px 0;
            }
        }

        &__price {
            font-size: 32px;
            padding: 10px 0;
        }

        &__addToCart {
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;

            @include mq(sm){
                flex-direction: row;
                max-width: 450px;
            }

            &__selectbox {
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                padding: 0 15px;
                margin-bottom: 10px;

                @include mq(sm){
                    width: 80px;
                    margin-right: 10px;
                }
            }

            ._button {
                max-width: none;
            }
        }

        &__options{
            text-align: center;
            color: $mainColor;

            @include mq(sm) {
                display: flex;
            }
            a {
                display: block;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 10px;

                &:first-child {
                    &:after {

                        @include mq(sm) {
                            content: '|';
                            margin: 0 20px;
                            display:block;
                            float: right;
                        }
                    }
                }

                i {
                    font-size: 21px;
                    margin-right: 10px;
                }
            }
        }

        &__details {
            color: $darkGray;
            padding: 20px 0;
            font-size: 14px;

            &__title {
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 700;
                margin: 0;
            }

            &__text {
                margin: 0;
                strong {
                    font-size: 16px;
                }
            }

            &__wrapper {
                @include trans;

                max-height: 100px;
                overflow: hidden;
                position: relative;

                &:after {
                    @include trans;
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 40px;
                    bottom: 0;
                    background: linear-gradient(to top, $white, rgba(255,255,255,0));
                }

                &.-open{
                    &:after {
                        height: 0;
                    }
                }
            }

            &__show {
                display: block;
                color: $mainColor;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                margin-top: 20px;

                @include mq(sm) {
                    text-align: left;
                }

                &:hover {
                    color: $mainColor;
                }
            }
        }
    }

    &__related,
    &__others {
        padding: 20px 0;

        .section-title {
            text-align: left;
            font-size: 24px;
            padding-left: 15px;

            @include mq(sm){
                padding-left: 0;
            }
        }
    }

    &__related {
        background: $lightestGray;

        @include mq(md) {
            background: $white;
        }
    }

    &__others {
        display: none;
        @include mq(md) {
            display: block;
        }
    }

    &__comment {
        padding-top: 40px;
        border-top: 1px solid $lightGray;

        &__title {
            color:$mainColor;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
        }
        &__form {
            .product__star-rating {
                margin: 10px 0;
            }

            &__group {
                margin-bottom: 20px;
            }

            &__label {
                display: block;
                font-size: 14px;
                font-weight: 700;
            }

            &__input {
                border: 1px solid $midGray;
                background: $white;
                width: 100%;
                height: 40px;
                line-height: 40px;

                &--textarea {
                    height: 200px;
                }
            }
        }
    }
}
