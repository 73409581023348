.legales {
    padding-bottom: 40px;
    &__title {
        color: $mainColor;
        font-weight: 700;
        font-size: 32px;
        margin: 0 0 40px;
    }

    &__subtitle {
        font-weight: 700;
        margin: 0 0 10px;
        font-size: 24px;
    }

    &__text {
        margin: 0 0 20px;
    }
}
