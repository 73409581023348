.filters{
}

.filter {
    $f: &;

    &__head {
        height: 40px;
        background: $lightGray;
        font-size: 14px;
        color: $textDark;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 40px;
        padding: 0 15px;
        position: relative;

        i {
            @include trans;
            position: absolute;
            right: 15px;
            top: 7px;
            transform: rotate(-90deg);
        }

        &--open {
            i {
                transform: rotate(0);
            }

            & + #{$f}__list {
                max-height: 500px;
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &__drop {
        border: 1px solid $lightGray;
        padding: 0 15px 0 40px;
        height: 40px;
        display: block;
        line-height: 40px;
        font-size: 14px;
        color: $textDark;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;

        &:before,
        &:after {
            @include trans;
            content: '';
            position: absolute;
            height: 2px;
            width: 10px;
            background: $textDark;
            left: 10px;
            top: 19px;
        }

        &:after {
            transform:rotate(90deg);
        }

        &--open {
            &:after {
                transform:rotate(0);
            }

            & + #{$f}__sublist {
                max-height: 600px;
                opacity: 1;
                padding:15px;
                pointer-events: all;
            }
        }
    }

    &__sublist {
        @include trans;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        padding: 0;
        border-left: 1px solid $lightGray;
        border-right: 1px solid $lightGray;
        pointer-events: none;

        label {
            font-weight: 300;
            font-size: 14px;
        }
    }

    &__list {
        @include trans;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
    }

    &__select {
        border-radius: 5px;
        border: 1px solid $darkGray;
        padding: 5px;
    }

    &__label {
        line-height: 32px;
        margin-right: 10px;
    }

    &--category {
        #{$f}__list {
            font-size: 14px;

            a {
                height: 32px;
                line-height: 32px;
                text-decoration: underline;
                padding: 0 15px;
            }
        }
    }

    &--order {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;

        @include mq(md){
            position: absolute;
            right: 30px;
            top:-10px;
            padding: 0;
        }
    }
}
