.shopcart {
    $s:&;

    padding:130px 0 40px;

    &__breadcrumb {
        padding: 20px 0;
        border-bottom: 1px solid $lightGray;
        position: fixed;
        top:90px;
        width: 100%;
        height: 60px;
        background: $white;
        z-index: 10;

        @include mq(md){
            position: relative;
            top: auto;
        }

        &__list {

            @include mq(md){
                display: flex;
                padding-bottom: 10px;
                justify-content: space-around;
            }

            li {
                display: none;
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
                color: $midGray;

                @include mq(md){
                    display: block;
                    font-size: 16px;

                    span {
                        display: none;
                    }
                }

                &.-active {
                    display: block;
                    color: $mainColor;
                }
            }
        }
    }

    &__body {
        padding-top: 40px;


    }

    &__title {
        color:$mainColor;
        font-weight: 700;
        margin: 0 0 20px;
        font-family: $altFont;
        font-size: 36px;
    }

    &__product {
        $f: &;

        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        border-bottom: 1px solid $midGray;

        @include mq(sm){
            border-bottom: 1px solid $lightGray;
        }

        &:not(:first-child) {
            #{$f}__heading {
                @include mq(sm){
                    display: none;
                }
            }
            #{$f}__content {
                @include mq(sm){
                    padding: 0;
                }
            }
        }

        &__heading {
            font-size: 16px;
            text-transform: uppercase;
            padding-bottom: 10px;
            font-weight: 700;
            margin: 0;
            border-bottom: 1px dotted $lightGray;
            min-height: 14px;

            @include mq(sm){
                border-bottom: 1px solid $lightGray;
            }
        }

        &__prod,
        &__add {
            flex-basis: 100%;
        }

        &__add {
            flex-basis: 100%;

            #{$f}__content {
                justify-content: flex-end;
            }

            @include mq(sm){
                flex-basis: 12.5%;
            }
        }

        &__prod {
            @include mq(sm){
                flex-basis: 62.5%;
            }
        }

        &__qty,
        &__price {
            flex-basis: 50%;

            @include mq(sm){
                flex-basis: 12.5%;
            }
        }
        &__price {
            text-align: right;

            @include mq(sm){
                text-align: center;
            }
        }

        &__content {
            padding:  10px 0 0;
            display: flex;

            @include mq(sm){
                align-items: center;
                min-height: 110px;
            }

            ._img {
                width: 100px;
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                    max-height: 100px;
                    display: block;
                    margin: auto auto 10px;

                    @include mq(sm){
                        margin: auto;
                    }
                }
            }

            ._data {
                padding-left: 20px;
                h4 {
                    font-size: 16px;
                    text-transform: uppercase;
                    padding-bottom: 10px;
                    font-weight: 700;
                    margin: 0;
                }

                p {
                    margin: 0;
                }
            }

            ._selectbox {
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                padding: 0 15px;
                margin-bottom: 10px;

                @include mq(sm){
                    width: 80px;
                    margin-right: 10px;
                }
            }

            ._price {
                font-size: 16px;
                text-align: right;
                display: block;
                width: 100%;
                font-weight: 700;

                @include mq(sm){
                    text-align: center;
                }
            }
        }
    }

    &__confirm {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        padding: 20px 0 0;

        ._button {
            flex-basis: 100%;
            max-width: none;
            margin-bottom: 10px;

            @include mq(sm){
                margin-left: 20px;
                flex-basis: auto;
            }

            &.-dark {
                background: $darkestGray;

                &:hover {
                    background: $darkGray;
                }
            }
        }
    }

    &__form {
        padding: 0 0 40px;
        max-width: 450px;

        &__intro {
            font-weight: 700;
            font-size: 16px;
            color: $darkGray;
            margin: 0 0 10px;
        }
        &__aux {
            color: $mainColor;
            font-size: 12px;
            text-decoration: underline;
            display: block;
            margin-bottom: 10px;

            @include mq(sm){
                display: none;
            }
        }
        &__group {
            margin-bottom: 20px;

            &--radio {
                display: flex;
                justify-content: space-between;

                select{
                    width: 48%;
                }
            }
        }

        &__radio {

            input{
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        &__label {
            display: block;
            font-size: 14px;
            font-weight: 700;
            color: $darkGray;
            text-transform: uppercase;

            ._light {
                font-weight:300;
            }
        }

        &__input {
            border: 1px solid $midGray;
            background: $white;
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding: 0 15px;

            &--textarea {
                height: 200px;
            }
        }
    }

    &__intro {
        font-weight: 700;
        font-size: 16px;
        color: $darkGray;
        margin: 0 0 10px;
    }

    &--login {
        .section-title {
            text-align: left;
            margin: 0 0 10px;
        }

        #{$s}__body {
            .col-sm-12 {
                &:nth-of-type(2) {
                    @include mq(sm){
                        border-left: 1px solid $lightGray;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &--retiro {
        .section-title {
            text-align: left;
            margin: 0 0 10px;
        }

        form {
            & > ._button {
                float: right;
            }
        }

        #{$s}__tabs {
            &__intro {
                font-weight: 700;
                font-size: 16px;
                color: $darkGray;
                margin: 0 0 10px;
            }
            &__selector {
                display: flex;
                flex-wrap: wrap;
                padding: 20px 0 0;

                ._button {
                    width: 100%;
                    max-width: none;
                    background: $darkestGray;

                    &:hover {
                        background: $darkGray;
                    }
                }

                li {
                    margin-bottom: 10px;
                    cursor: pointer;

                    @include mq(sm){
                        flex-basis: calc(50% - 15px);
                        width: calc(50% - 15px);

                        &:last-child {
                            margin-left: 30px;
                        }
                    }

                    &.-active {
                        ._button {
                            background: $mainColor;

                            &:hover {
                                background: $linkHover;
                            }
                        }
                    }
                }
            }
        }

        #{$s}__tab {
            padding: 40px 0;
            display: none;

            &.-active {
                display: block;
            }
        }
    }

    &__resume {
        &__item {

            &:not(:first-of-type) {
                padding: 20px 0;
                border-bottom: 1px solid $lightGray;
            }
        }

        &__confirm {
            padding: 20px 0;
            display: flex;
            justify-content: flex-end;

            ._button {
                flex-basis: 100%;
                max-width: none;
                margin-bottom: 10px;

                @include mq(sm){
                    margin-left: 20px;
                    flex-basis: auto;
                }

                &.-dark {
                    background: $darkestGray;

                    &:hover {
                        background: $darkGray;
                    }
                }
            }
        }
    }

    &__data {
        display: flex;
        max-width: 700px;
        justify-content: space-between;

        &__item {
            strong {
                text-transform: uppercase;
            }
        }
    }
}
