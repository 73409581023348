.cuenta {
    padding-bottom: 40px;

    &__nav {
        border-top: 1px solid $lightGray;
        padding-bottom: 40px;
        li {
            border-bottom: 1px solid $lightGray;
            color: $textDark;
            a {
                display: block;
                height: 40px;
                line-height: 40px;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 14px;
            }

            &.-active {
                color: $mainColor;
            }
        }
    }

    &__title {
        color:$mainColor;
        font-weight: 700;
        margin: 0 0 20px;
        font-family: $altFont;
        font-size: 36px;
    }

    &__text {
        font-size: 16px;
        line-height: 24px;

        strong{
            display: block;
            font-size: 18px;
            margin-bottom: 5px;
        }
    }

    &__section {
        padding-top: 40px;

        &__name {
            border-bottom: 1px solid $lightGray;
            padding-bottom: 10px;
            margin: 0 0 20px;
            font-weight: 700;
            font-size: 24px;
        }

        &__module {
            &:not(:first-child){
                float: right;
            }
            &__name {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 700;
                margin: 0;
            }
            &__link {
                font-weight: 700;
                text-decoration: underline;
                color: $mainColor;
                margin-bottom: 20px;
                display: block;
            }
            &__text {
                margin: 0;

                &:last-of-type {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &__favorito {
        $f: &;

        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        border-bottom: 1px solid $midGray;

        @include mq(sm){
            border-bottom: 1px solid $lightGray;
        }

        &:not(:first-child) {
            #{$f}__heading {
                @include mq(sm){
                    display: none;
                }
            }
            #{$f}__content {
                @include mq(sm){
                    padding: 0;
                }
            }
        }

        &__heading {
            font-size: 16px;
            text-transform: uppercase;
            padding-bottom: 10px;
            font-weight: 700;
            margin: 0;
            border-bottom: 1px dotted $lightGray;
            min-height: 14px;

            @include mq(sm){
                border-bottom: 1px solid $lightGray;
            }
        }

        &__prod,
        &__add {
            flex-basis: 100%;
        }

        &__add {
            flex-basis: 100%;

            @include mq(sm){
                flex-basis: 25%;
            }
        }

        &__prod {
            @include mq(sm){
                flex-basis: 50%;
            }
        }

        &__qty,
        &__price {
            flex-basis: 50%;

            @include mq(sm){
                flex-basis: 12.5%;
            }
        }
        &__price {
            text-align: right;

            @include mq(sm){
                text-align: center;
            }
        }

        &__content {
            padding:  10px 0 0;
            display: flex;

            @include mq(sm){
                align-items: center;
                min-height: 110px;
            }

            ._img {
                width: 100px;
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                    max-height: 100px;
                    display: block;
                    margin: auto auto 10px;

                    @include mq(sm){
                        margin: auto;
                    }
                }
            }

            ._data {
                padding-left: 20px;
                h4 {
                    font-size: 16px;
                    text-transform: uppercase;
                    padding-bottom: 10px;
                    font-weight: 700;
                    margin: 0;
                }

                p {
                    margin: 0;
                }
            }

            ._selectbox {
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                padding: 0 15px;
                margin-bottom: 10px;

                @include mq(sm){
                    width: 80px;
                    margin-right: 10px;
                }
            }

            ._price {
                font-size: 16px;
                text-align: right;
                display: block;
                width: 100%;
                font-weight: 700;

                @include mq(sm){
                    text-align: center;
                }
            }
        }
    }
}
