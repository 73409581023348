@import url('https://fonts.googleapis.com/css?family=PT+Sans:200,400,700');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
	font-family: 'Myriad';
	src: url('/fonts/subset-Myriad-BdWeb.eot');
	src: url('/fonts/subset-Myriad-BdWeb.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-Myriad-BdWeb.woff2') format('woff2'),
		url('/fonts/subset-Myriad-BdWeb.woff') format('woff'),
		url('/fonts/subset-Myriad-BdWeb.ttf') format('truetype'),
		url('/fonts/subset-Myriad-BdWeb.svg#Myriad-BdWeb') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad';
	src: url('/fonts/subset-Myriad-ItWeb.eot');
	src: url('/fonts/subset-Myriad-ItWeb.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-Myriad-ItWeb.woff2') format('woff2'),
		url('/fonts/subset-Myriad-ItWeb.woff') format('woff'),
		url('/fonts/subset-Myriad-ItWeb.ttf') format('truetype'),
		url('/fonts/subset-Myriad-ItWeb.svg#Myriad-ItWeb') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad';
	src: url('/fonts/subset-Myriad-Web.eot');
	src: url('/fonts/subset-Myriad-Web.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-Myriad-Web.woff2') format('woff2'),
		url('/fonts/subset-Myriad-Web.woff') format('woff'),
		url('/fonts/subset-Myriad-Web.ttf') format('truetype'),
		url('/fonts/subset-Myriad-Web.svg#Myriad-Web') format('svg');
	font-weight: normal;
	font-style: normal;
}
