// Import Modules
@import 'mixins';
@import 'libraries';
@import 'modules';
@import 'pages';
@import 'components';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
    font-family: $mainFont;
    font-weight: 200;
    overflow-x: hidden;
    width: 100vw;
    font-size: $sm;
}

a {
    color:inherit;
    transition: all $duration $easingLight;

    &:hover,
    &:focus{
        color:inherit;
        text-decoration: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

main {
    padding-top: 90px;
    @include mq(md){
        padding-top: 130px;
    }
}

.section-title {
    color: $mainColor;
    font-size: 28px;
    text-align: center;
    font-family: $altFont;
    font-weight: 700;
    margin: 20px 0 40px;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
