// pagination styles
.pagination {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: 1px solid $lightGray;

    &__pages {
        text-align: center;
        white-space: nowrap;
        overflow-x: auto;
        padding-bottom: 40px;

        @include mq(md) {
            display: flex;
        }
    }

    &__wrapper {
        height: 90px;
        overflow:hidden;
    }

    li {
        color: $midGray;
        font-weight: 700;
        font-size: 14px;
        width: 60px;
        height: 90px;
        line-height: 90px;
        text-align: center;
        margin-right: 10px;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;

        &:first-child {
            color: $mainColor;
        }
    }

    &__btn {
        background: none;
        width: 60px;
        text-align: center;
        border: none;
        color: $mainColor;
    }
}
