.header {

    $h: &;

    position: fixed;
    background: $mainColor;
    height: 90px;
    width: 100%;
    z-index: 100;
    box-shadow: 0 1px 1px rgba(0,0,0,.3);

    @include mq(md) {
        background: $white;
        height: 90px;
    }

    &__overlay {
        @include mq(md){

            @include trans;

            position: fixed;
            background: rgba(0,0,0,.7);
            top: 90px;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 0;
            pointer-events: none;
            opacity: 0;

            &--visible {
                pointer-events: all;
                opacity: 1;
            }
        }
    }

    &__container {
        position: relative;
        height: 100%;
    }

    &__logo {
        background: url('/images/logo-back.png');
        background-size: cover;
        position: absolute;
        top: 12px;
        left: 0;
        width: 160px;
        height: 25px;
        right: 0;
        margin: auto;

        @include mq(md) {
            width: 270px;
            height: 50px;
            position: relative;
            right: auto;
            margin: 0;
            float: left;
            padding: 10px 0 0 10px;
            top: 20px;
        }

        a {
            display: block;
        }

        img{
            width: 115px;
            @include mq(md){
                width: 175px;
            }
        }
    }

    &__topnav {
        @include mq(md) {
            display: flex;
            justify-content: space-around;
            float: right;
            width: 60%;
            position: relative;
            top: 20px;
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        left: 15px;
        top: 12px;

        span {
            background-color: $white;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md){
            display: none;
        }
    }

    &__link {
        color:$white;
        display: block;
        position: absolute;
        top: 12px;

        @include mq(md) {
            position: static;
            color: $mainColor;
            text-align: center;
        }

        span {
            display: none;

            @include mq(md) {
                display: block;
                font-size: 12px;
                color: $darkestGray;
            }
        }

        i {
            font-size: 21px;
        }

        &--account {
            left: 60px;
            order:1;
        }

        &--favoritos {
            display: none;
            order:1;

            @include mq(md) {
                display: block;
            }
        }

        &--stores {
            right: 50px;
            order:1;
        }

        &--cart {
            right: 15px;

            @include mq(md) {
                display: flex;
                order:2;
                margin-top: 10px;
            }

            span {
                margin-left: 5px;
            }

        }
    }

    &__searchbox {
        position: absolute;
        bottom:0;
        height: 45px;
        background: $white;
        width: 100%;
        left:0;

        @include mq(md) {
            position: relative;
            width: 300px;
            border: 1px solid $lightGray;
            order:1;
        }

        &__input {
            height: 45px;
            line-height: 45px;
            padding: 0 0 0 10px;
            border: none;
            width: 100%;
            font-size: 14px;

            @include mq(md) {
                height: 43px;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &__button {
            border: none;
            background: none;
            height: 45px;
            width: 45px;
            position: absolute;
            right: 0;
            top:0;

            @include mq(md) {
                height: 43px;
            }
        }
    }

    &__nav {
        @include trans;

        background: $mainColor - #333;
        color: $white;
        position: fixed;
        top: 90px;
        left:0;
        right: 0;
        bottom: 0;
        overflow: auto;
        transform: translateX(-100%);

        @include mq(md) {
            background: $mainColor;
            transform: translateX(0);
            position: relative;
            top: auto;
            width: 100%;
            overflow: visible;
        }
    }

    &__menu {

        @include mq(md) {
            display: flex;
            justify-content: space-between;
            margin-right: auto;
            margin-left: auto;
            padding-left: 15px;
            padding-right: 15px;
            max-width: 970px;
        }

        @include mq(lg){
            max-width: 1170px;
        }

        &__item {
            border-bottom: 1px solid transparentize($white, .8);
            position: relative;

            @include mq(md) {
                position: static;
            }


            & > a {
                @include trans;

                display: block;
                height: 40px;
                line-height: 40px;
                padding: 0 15px;
                position: relative;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;

                i {
                    transform: translateY(7px);
                }

                &:hover {
                    background: rgba(255,255,255,.15);
                }
            }

            &--open {
                background: rgba(255,255,255,.15);

                #{$h}__supermenu {
                    max-height: 100vh;

                    @include mq(md){
                        max-height: 50vh;
                        height: 360px;
                    }
                }
            }
        }
    }

    &__supermenu {
        $sh: &;

        @include trans;

        background: $white;
        color:$darkestGray;
        max-height: 0;
        overflow: hidden;

        @include mq(md) {
            position: absolute;
            left: 0;
            top: 40px;
            right: 0;
        }

        &__container {
            padding-left: 0;
            padding-right: 0;
            @include mq(md) {
                height: 100%;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &__links {

            @include mq(md) {
                position: relative;
                width: 70%;
                height: 100%;
            }

            &__item {
                position: relative;

                @include mq(md) {
                    position: static;
                    width: 40%;
                }

                & > a {
                    @include trans;

                    display: block;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 15px;
                    position: relative;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;

                    &:hover {
                        color:$link;
                        background: transparentize($mainColor, .9);
                    }

                    i {
                        font-size: 21px;
                        transform: translateY(5px);
                    }
                }

                &:hover {
                    @include mq(md){
                        background: transparentize($mainColor, .9);
                    }

                    #{$sh}__submenu {
                        max-height: 100vh;

                        @include mq(md) {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }

        &__submenu {
            @include trans;

            max-height: 0;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;

            @include mq(md) {
                position: absolute;
                left: 40%;
                top: 0;
                width: 60%;
                bottom: 0;
                border-left: 1px solid $lightGray;
                align-content: flex-start;
                max-height: 100vh;
                opacity: 0;
                pointer-events: none;
                padding: 20px 0 0 20px;
            }

            &__item{
                flex-basis: 50%;
                border-bottom: 1px solid $lightGray;

                @include mq(md){
                    border: none;
                }

                & > a {
                    display: block;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 15px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 700;

                    &:hover {
                        color: $link;
                        background: $lightestGray;
                    }
                }

                &:nth-child(odd) {
                    border-right: 1px solid $lightGray;

                    @include mq(md){
                        border: none;
                    }
                }
            }
        }

        &__img {
            display: none;
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
