.tiendas {
    &__main {
        .col-xs-24 {
            position: static;
        }

        &__title {
            color: $mainColor;
            font-weight: 700;
            font-size: 32px;
            margin: 0 0 20px;
        }

        &__search {
            display: flex;
            padding-bottom: 20px;

            &__input {
                flex-grow: 2;
                flex-basis: auto;
                padding: 0 15px;
                font-size: 14px;
                text-transform: uppercase;
            }
        }

        &__container {
            position: relative;
        }

        &__map {
            width: 100%;
            position: relative;
            height: 0;
            padding-bottom: 80%;

            @include mq(sm) {
                position: absolute;
                right: 0;
                top:0;
                bottom: 0;
                left:calc(41.667% + 30px);
                width: auto;
                padding-bottom: 0;
                height: auto;
            }

            iframe {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                border: none;
            }
        }

        &__stores {
            overflow-y: auto;
            max-height: 480px;
            border-top: 1px solid $lightGray;
            border-bottom: 1px solid $lightGray;

            &::-webkit-scrollbar-track {
            	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.15);
            	background-color: $lightestGray;
            }

            &::-webkit-scrollbar {
            	width: 5px;
            	background-color: $lightestGray;
            }

            &::-webkit-scrollbar-thumb {
            	background-color: $mainColor;
            }
        }

        &__store {
            display: flex;
            align-items: center;
            padding: 20px 0;
            border-bottom: 1px solid $lightGray;

            &__data {
                flex-grow: 2;
                flex-basis: auto;
                color: $darkGray;
            }

            &__name {
                color:$mainColor;
                font-size: 18px;
                font-weight: 700;
            }

            i {
                width: 50px;
                font-size: 42px;
                color:$mainColor;
            }
        }
    }
    &__popular {
        padding: 20px 0;

        .section-title {
            text-align: left;
            font-size: 24px;
            padding-left: 15px;

            @include mq(sm){
                padding-left: 0;
            }
        }
    }
}
