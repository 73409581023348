.newsletter{
    &__container {
        background: $mainColor;
        color: $white;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__title {
        font-size: 28px;
        font-family: $altFont;
        font-weight: 700;
        margin: 0;
    }

    &__desc {
        font-size: 18px;
        line-height: 36px;
        margin: 0;
    }

    &__form {
        position: relative;
        height: 50px;
        top: 7px;
    }

    &__input {
        position: absolute;
        left: 0;
        top:0;
        height: 50px;
        width: 100%;
        border: none;
        padding: 0 10px;
    }

    &__btn {
        position: absolute;
        right: 0;
        top:0;
        height: 50px;
        width: 50px;
        text-align: center;
        background: $white;
        border: none;
        border-left: 1px solid $lightGray;

        i{
            color: $mainColor;
            line-height: 50px;
        }
    }
}
