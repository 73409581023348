.popular {
    .slick-prev {
        left: -50px;

        &:before {
            color:$mainColor;
        }
    }
    .slick-next {
        right: -50px;

        &:before {
            color:$mainColor;
        }
    }
}
